import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import Sidebar from "../SidebarController/Sidebar";
import Topbar from "../SidebarController/Topbar";
import React,{useState} from "react";

const MainLayout = () => {
  const[open, setOpen]= useState(false)

  const handleChange =()=>{
    sessionStorage.setItem('sidebarPosition', !open)
    setOpen(!open)
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Topbar open={open} changeEvent={handleChange}/>
      <Box
        component="nav"
        sx={{
          width: open ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.xsWidth,
          flexShrink: 0
        }}
      >
        <Sidebar open={open} changeEvent={handleChange}/>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${open ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.xsWidth})`,
          minHeight: "100vh",
          background: colorConfigs.mainBg
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;