const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const isEmpty = inputObject => {
    return Object.keys(inputObject).length === 0;
};

export const LoginValidateForm = (data) => {
    console.log(data)
    let errors = {};
    if(data){
        if (!data.email.trim()) {
            errors.email = 'Email is required';
        } else if (!isValidEmail(data.email)) {
            errors.email = 'Invalid email format';
        }
        
        if (!data.password.trim()) {
            errors.password = 'Password is required';
        } else if (data.password.length < 6) {
            errors.password = 'Password must be at least 6 characters long';
        }
    }
    
    console.log(isEmpty(errors)) 
    let displayError = isEmpty(errors) ? null : errors
    return displayError;
  };

export const SignUpValidateForm = (data) => {
    let errors = {};
        if(data){
            if (!data.name.trim()) {
                errors.name = 'Name is required';
                }
            
                if (!data.email.trim()) {
                errors.email = 'Email is required';
                } else if (!isValidEmail(data.email)) {
                errors.email = 'Invalid email format';
                }

                if (!data.mobile.trim()) {
                errors.mobile = 'mobile number is required';
                } else if (data.mobile.length != 8) {
                errors.mobile = 'invalid mobile number';
                }
            
                if (!data.password.trim()) {
                errors.password = 'Password is required';
                } else if (data.password.length < 6) {
                errors.password = 'Password must be at least 6 characters long';
                }
        
                if (!data.confirmPassword.trim()) {
                    errors.confirmPassword = 'Confirm Password is required';
                } else if (data.password != data.confirmPassword) {
                    errors.confirmPassword = 'Please check both password and confirm password is same';
                }
        }

    console.log(isEmpty(errors)) 
    let displayError = isEmpty(errors) ? null : errors
  
    return displayError;
};


export const ForgetPasswordValidateForm = (data) => {
    let errors = {};
    if(data){
        if (!data.email.trim()) {
            errors.email = 'Email is required';
        } else if (!isValidEmail(data.email)) {
            errors.email = 'Invalid email format';
        }
    }
    let displayError = isEmpty(errors) ? null : errors
    return displayError;
  };
