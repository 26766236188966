import React, { useState, useEffect, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom"; 
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Logo from '../Assets/Logo.png';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import colorConfigs from '../configs/colorConfigs';
import Link from '@mui/material/Link';
import { LoginValidateForm } from './FormValidation';
import AuthService from '../Services/AuthService';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import BackDropLoading from "../Components/Loading";

let validation = {
    email: false,
    password: false
}

let validationMsg = {
    email: '',
    password: ''
}
  
function Login(){
    const navigate = useNavigate();
    const [valid, setValid] = useState(validation)
    const [msg, setMsg] = useState(validationMsg)
    const [emailId, setEmailId] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        setValid(validation)
        setMsg(validationMsg)
    },[emailId, password])

    const handleSubmit =async() => {
        setIsLoading(true) 
        let value = {
            email: emailId,
            password: password,
        }
        let err = LoginValidateForm(value)
        console.log("err", err)
        if(err == null){
            await AuthService.onSignIn(value).then((response)=>{
                let uId = response && response.user && response.user.uid;
                let value = {
                    "emailId": emailId,
                    "password": uId
                }
                onLogin(value)
            }).catch((err)=>{
                console.log(JSON.stringify(err))
               
                let errData = err && err.code && err.code.split("/");
                let eValue = errData[1].replace(/-/g, " ")
                let errorValue =  eValue  ? eValue : 'Kindly try after sometime';
                setIsLoading(false)
                toast.error(errorValue);
            })
        }
        else{
            setIsLoading(false)
            if(err && err.email){
                setMsg((prevState) => ({
                    ...prevState,
                    email: err.email
                }));
                setValid((prevState) => ({
                    ...prevState,
                    email: true
                }));
            }
            if(err && err.password){
                setMsg((prevState) => ({
                    ...prevState,
                    password: err.password
                }));
                setValid((prevState) => ({
                    ...prevState,
                    password: true
                }));
            }
        }
        
    };

    const onLogin=async(data)=>{
        await AuthService.loginUser(data).then((response)=>{
            console.log("response", response)
            let role = response && response.data && response.data.data && response.data.data.role;
            console.log(role)
            if(role == 'ADMIN'){
                let user = response && response.data
                let uDetails = JSON.stringify(user)
                sessionStorage.setItem('userDetails', uDetails)
                toast.success("Registered Successfully");
                setIsLoading(false) 
                navigate("/dashboard")  
            }      
            else{
                toast.success("Unauthorised Access");
                setIsLoading(false) 
            }         
            //navigate("/dashboard")
        }).catch((e)=>{
            console.log(e)
            let err = e && e.response && e.response.data && e.response.data.error &&
            e.response.data.error.reason;
            let errValue = err ? err :"Service unavailable, Kindly try after sometime"
            setIsLoading(false) 
            toast.error(errValue);
        })
    }

    return(
        <Grid container 
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{backgroundColor: colorConfigs.sidebar.bg, minHeight: '100vh'}}>
            <Grid item>
                <img src={Logo} width={`100%`} height={80}/>
                <Grid item xs={12} sm={12} md={12} >
              </Grid>
                <Card sx={{ maxWidth: 500}}>
                    <CardContent>
                        <Box
                            sx={{
                                marginTop: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                            >
                               
                              
                                <Box sx={{ mt: 1 }}>
                                <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                error ={valid.email}
                                onChange={(e)=> setEmailId(e.target.value)}
                                helperText={valid.email ? msg.email : ''}
                                autoFocus
                                />
                                <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                error ={valid.password}
                                helperText={valid.password ? msg.password : ''}
                                onChange={(e)=> setPassword(e.target.value)}
                                autoComplete="current-password"
                                />
                                
                                <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={()=> handleSubmit()}
                                >
                                Sign In
                                </Button>
                                <Grid container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center" style={{padding: `10px`}}>
                                    <Grid item xs={4} sm={6} md={6}>
                                        <Link onClick={()=>  navigate("/recoverpassword")} variant="body2">
                                        Recover Password
                                        </Link>
                                    </Grid>
                                    <Grid item xs={8} sm={6} md={6} >
                                        <Link onClick={()=>  navigate("/signup")} variant="body2" style={{float: 'right'}}>
                                        {"Sign Up"}
                                        </Link>
                                    </Grid>
                                    </Grid>
                            </Box>
                        </Box>
                    </CardContent>
                    {isLoading && <BackDropLoading  open={isLoading} />}
                    <ToastContainer />
                </Card>
            </Grid>
       </Grid>
    );
}


export default Login;