import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import React from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import { useNavigate , redirect} from "react-router-dom";
import logo from '../../Assets/Logo.png';
// import { signOut } from "firebase/auth";
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import AuthService from "../../Services/AuthService";

const Topbar = (props) => {
  const theme =  useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = async() => { 
     await AuthService.onSignOut().then((response)=>{
      sessionStorage.removeItem('userDetails')
      navigate('/login')  
    }).catch((err)=>{
      console.log(err)
    })
   
  }

  const navigateToDashboard =() => {    
    navigate('/dashboard') 
    navigate(0) 
  }


    return (
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${props.open ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.xsWidth})`,
          ml: props.open ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.xsWidth,
          boxShadow: "unset",
          backgroundColor: colorConfigs.topbar.bg,
          color: colorConfigs.topbar.color
        }}
      >
        <Toolbar  sx={{ justifyContent: "space-between" }}>          
          {/* <Typography variant="h5" style={{ color: theme.palette.primary.main}}>
            {props && !props.open &&(
              <IconButton sx={{color: colorConfigs.topbar.color}} onClick={props.changeEvent}>
                <img src={logo} width={180} height={50} style={{padding: `10px`}}/>
              </IconButton>
            )}            
          </Typography> */}
          <>
          <IconButton sx={{color: colorConfigs.topbar.color}}>
            {props && !props.open ? (
              <>
              <MenuIcon onClick={props.changeEvent}/>
              <img src={logo} width={180} style={{padding: `10px`}}
              onClick={() => navigateToDashboard()}/>
              </>) : ''}
          </IconButton>
          
          </>
          <IconButton 
           onClick={handleClick}
           size="small"
           sx={{ ml: 2 }}
           aria-controls={open ? 'account-menu' : undefined}
           aria-haspopup="true"
           aria-expanded={open ? 'true' : undefined}>
            <Avatar><AccountCircleIcon /></Avatar>
          </IconButton>
          <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,              
              }}            
            > 
             <MenuItem onClick={() => logOut()}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu> 
        </Toolbar>
      </AppBar>
    );
};
  
export default Topbar;


/*
 
*/