import React,{useState, useEffect} from "react";
import { Card, Typography, Grid, IconButton, TextField, MenuItem } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RegisterPopUp from "./RegisterPopUp";
import SuccessToast from "../Components/ToastBox";
import DataSerices from "../Services/DataSerices";
import MUIDataTable from "mui-datatables";
import { Button } from "@mui/material";
import BeenhereIcon from '@mui/icons-material/Beenhere';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BackDropLoading from "../Components/Loading";
import DoneIcon from '@mui/icons-material/Done';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import SuccessBox from "../Components/SuccessBox";
import EditIcon from '@mui/icons-material/Edit';

const initialToast ={
    open: false,
    msg: ''
}

const approveText = "Are you sure, you want to activate this user?";
const declineText = "Are you sure, you want to deactivate this user?";
const activatedMsg = "Activated Successfully...";
const deactivatedMsg = "Deactivated Successfully...";

const statusList =[
    {
      value: 'ACTIVE',
      label: 'Active',
    },
    {
      value: 'INACTIVE',
      label: 'Inactive',
    },
];

function CustomToolbarComponent({data, onCreate, handleChange}) {
    
    const handleSelect=(e)=>{
        handleChange(e)
    }

    return (
        <>
         <IconButton  onClick={()=> onCreate('Create')}>
            <AddCircleIcon />
        </IconButton>
        <TextField
        id="outlined-select-currency"
        select
        size="small"
        label="Select"
        value={data}
        onChange={(e)=> handleSelect(e.target.value)}
        >
            {statusList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                {option.label}
                </MenuItem>
            ))}
        </TextField>
        </>
    );
  };

function AccessCardRegistration(){
    const [list, setList] = useState([]);
    const [duplicateList, setDuplicateList] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex]= useState(null);
    const[openModal, setOpenModal] = useState(false);
    const [toast, setToast] = useState(initialToast);
    const [isLoading, setIsLoading] = useState(false);

    const [message, setMessage] = useState({
        status: '',
        msg: ''
    });
    const [editData, setEditData]= useState({
        status: 'Create',
        data: null
    });
    const [selectValue, setSelectValue] = useState('ACTIVE');

    const handleChange=(e)=>{
        setSelectValue(e)
    }
    
    const options = {
        /* selectableRows: false,
        search: true,
        filter: false,
        download: false,
        serverSide: true,
        print: false,
        viewColumns: false,
        filterType: 'custom',
        responsive: 'vertical',
        pagination: list.length > 9 ? true : false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10],
        onSearchChange: (searchText) => {
            console.log("search: " + searchText);
           if(searchText == null){
                setList(duplicateList)
            }
            else{
                console.log("duplicateList", duplicateList)
                if(duplicateList.length > 1 ){
                    const res = duplicateList.filter(obj => Object.values(obj).some(val => 
                        val !=undefined && val != null && val.toString().toUpperCase().includes(searchText.toString().toUpperCase())
                    ));
                    //console.log("res", res) val.toUpperCase().includes(searchText.toUpperCase())
                    setList(res)
                }
            }
              
        },    
        customToolbar: () => {
            return (
                <CustomToolbarComponent  handleChange={onCreateOrEdit}/>
            );
        }      */
        filter: false,
        selectableRows: false,
        search: true,
        serverSide: false,
        download: false,
        print: false,
        viewColumns: false,
        pagination: true,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50],
        onSearchChange: (searchText) => {
            console.log("search: " + searchText);
           if(searchText == null){
                setList(duplicateList)
            }
            else{
                console.log("duplicateList", duplicateList)
                if(duplicateList.length > 1 ){
                    const res = duplicateList.filter(obj => Object.values(obj).some(val => 
                        val !=undefined && val != null && val.toString().toUpperCase().includes(searchText.toString().toUpperCase())
                    ));
                    //console.log("res", res) val.toUpperCase().includes(searchText.toUpperCase())
                    setList(res)
                }
            }
              
        },  
        customToolbar: () => {
            return (
                <CustomToolbarComponent data={selectValue} onCreate={onCreateOrEdit} handleChange={handleChange}/>
            );
        }
    };

    const columns =[
        {
            name: "id",
            label: "S/No.",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span>{tableMeta.rowIndex + 1}</span>
                    );
                }
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "mobileNo",
            label: "Mobile Number",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "unitNo",
            label: "Unit Number",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: '',
            label: '',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <>
                        <div
                          style={
                            {
                              // display: 'flex',
                              // flexDirection: 'row',
                              // alignItems: `flex-start`,
                              // justifyContent: 'space-evenly'
                            }
                          }
                        >
                          <Button
                            variant="contained"
                            color="info"
                            style={{ marginLeft: `10px` }}
                            onClick={() =>
                              onCreateOrEdit("Edit", tableMeta.rowIndex)
                            }
                          >
                            <EditIcon />
                            Edit
                          </Button>

                          {!tableMeta?.tableData[tableMeta?.rowIndex]
                            ?.uploaded ? (
                            <Button
                              variant="contained"
                              color="success"
                              style={{ marginLeft: `10px` }}
                              onClick={() =>
                                handlePopUp("Activated", tableMeta.rowIndex)
                              }
                            >
                              <DoneIcon />
                              Activate
                            </Button>
                          ) : (
                            ""
                          )}

                          {tableMeta?.tableData[tableMeta?.rowIndex]
                            ?.uploaded ? (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ marginLeft: `10px` }}
                              onClick={() =>
                                handlePopUp("Deactivated", tableMeta.rowIndex)
                              }
                            >
                              <CancelPresentationIcon />
                              Deactivate
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    );
                },
            },
        },
    ]

    useEffect(()=>{
        getList();
     },[selectValue])
 

    const getList=async()=>{
        setIsLoading(true)
        let data = {
            status: selectValue,
        }
        await DataSerices.getAccessList(data).then((res)=>{
            let response = res && res.data && res.data.data;
            setList(response)
            setDuplicateList(response)
            setIsLoading(false)
        }).catch((error) => {
            console.log("error", error)
            setIsLoading(false)
        });
    }

    const handlePopUp=(e , index)=>{
        setSelectedIndex(list[index])
        if(e == 'Activated'){
            if(list[index].uploaded){
                let activatedData = {
                    open: true,
                    msg: `User is already added`
                }
                setToast(activatedData)
            }
            else{
                let data = {
                    status: e,
                    msg: approveText
                  }
                  setMessage(data)
                  setOpen(true)
            }
        }
        if(e == 'Deactivated'){
            let data = {
              status: e,
              msg: declineText
            }
            setMessage(data)
            setOpen(true)
        }
    }

    const handleYes = async(e) => {
        handleSuccessClose();
        setIsLoading(true)
        let data = {
            cardNo : selectedIndex.cardNo,     
            mobileNo: selectedIndex.mobileNo,
            name: selectedIndex.name,
            unitNo: selectedIndex.unitNo
        }
        if(e == 'Activated'){
            addCard(data)
        }
        if(e == 'Deactivated'){
            deleteCard(data)
        }
    }

    const addCard=async(data)=>{
        await DataSerices.addAccessCard(data).then((res)=>{
            let activatedData = {
                open: true,
                msg: activatedMsg
            }
            setToast(activatedData)
            getList();
        }).catch((error) => {
            console.log("error", error)
            setIsLoading(false)
        });
    }

    const deleteCard=async(data)=>{
        await DataSerices.deleteAccessCard(data).then((res)=>{
            let deactivatedData = {
                open: true,
                msg: deactivatedMsg
            }
            setToast(deactivatedData)
            getList();
        }).catch((error) => {
            console.log("error", error)
            setIsLoading(false)
        });
    }

    const handleSuccessClose = () => {
        setOpen(false);
    };

    const handleClose=(e)=>{
        setOpenModal(false)
        if(e != null){
            let data = {
                open: true,
                msg: e
            }
            setToast(data)
            getList();
        }
    }

    const handleToastClose=()=>{
        setToast(initialToast)
    }

    const onCreateOrEdit=async(val, index)=>{
        let x = val == 'Create' ? null : list[index];
        let data = {
            status: val,
            data: x
        }
        await setEditData(data)
        setOpenModal(true)
    }

    return(
        <React.Fragment>
            <Card>
               {/*  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container>
                            <Grid item xs={10} sm={11} md={11}>
                                <Typography variant="h6" gutterBottom style={{padding: `20px`}}>
                                    Access Card Registration Portal
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sm={1} md={1}>
                                <IconButton style={{padding: `20px`}} onClick={()=> onCreateOrEdit('Create')}>
                                    <AddCircleIcon color="secondary"/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    
                </Grid> */}
                {isLoading && <BackDropLoading  open={isLoading} />}
                <MUIDataTable
                title={"Access Card Registration Portal"}
                data={list}
                columns={columns}
                options={options}
                />
               <RegisterPopUp open={openModal} handleClose={handleClose} data={editData}/>
            </Card>
            <SuccessBox
                open={open}
                handleClose={handleSuccessClose}
                status={message.status}
                message={message.msg}
                text={'Confirm...!'}
                handleYes={handleYes}
            />
            <SuccessToast
                open={toast.open}
                handleClose={handleToastClose}
                message={toast.msg}
            />
        </React.Fragment>
    );
}

export default AccessCardRegistration;