import { auth } from './Firebase';
import {  createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut,
     sendPasswordResetEmail  } from 'firebase/auth';
import http_common from './http-common'


const onSignUp =(data)=> {
    return createUserWithEmailAndPassword(auth, data.email, data.password);
};

const onSignIn =(data)=> {
    return signInWithEmailAndPassword(auth, data.email, data.password);
};

const onRecover =(data)=> {
    return sendPasswordResetEmail(auth, data.email);
};

const onSignOut =()=> {
    return signOut(auth);
};

const loginUser=(data)=>{
    //user/login user/{type} 
    return  http_common.post(`user/login`, data);
} 

const registerUser=(data, type)=>{
    //user/login user/{type} 
    return  http_common.post(`user/${type}`, data);
} 

export default { 
    onSignUp,
    onSignIn,
    onRecover,
    onSignOut,
    loginUser,
    registerUser
}