import http_common from './http-common'


/* /api/user/role/{role}/status/{status} */
/* /api/user/status */

const getUserList =(data)=> {
  return  http_common.get(`/user/role/${data.role}/status/${data.status} `);
};


const updateUser =(data)=> {
    return  http_common.put(`/user/status`, data);
};

const updateUserDetails =(data)=> {
  return  http_common.put(`/user`, data);
};

const registerAccessCard=(data)=>{
  return  http_common.post(`/condo/accessCard/registration`, data);
}

const editAccessCard=(data)=>{
  return  http_common.post(`/condo/accessCard/edit`, data);
}

const getAccessList=(data)=>{
  return  http_common.get(`/condo/accessCard/list?status=${data.status}`);
}

const deleteAccessCard=(data)=>{
  return  http_common.post(`/condo/accessCard/delete`, data);
}

const addAccessCard=(data)=>{
  return  http_common.post(`/condo/accessCard/add`, data);
}


const getAccessLogs=(data)=>{
  return  http_common.get(`/condo/accessCard/logs?pageNo=${data.page}&pageSize=${data.size}&type=${data.type}`);
}

const getGateLogs=()=>{
  return  http_common.get(`/condo/accessCard/gate/logs`);
}
const getVehicleAuditReport =(data)=>{
  return  http_common.get(`/visitorInfo/audit/data?overstay=${data.overstay}&type=${data.type}&pageNo=${data.pageNo}&pageSize=${data.pageSize}&vehicleNo=${data.filterData.vehicleNo}&visitorType=${data.filterData.visitorType}`);
}

const searchEntryExit = (data) =>{
  let x = data.field;
  let a = x.category==`All`?'': x.category.length>0 ? `&category=${data.field.category}` : '';
  let b = x.name.length>0 ? `&name=${data.field.name}` : '';
  let c = x.block.length>0 ? `&blockNo=${data.field.block}` : '';
  let d= x.vehicleNo.length>0 ? `&vehicleNos=${data.field.vehicleNo}` : '';
  let e= x.contactNo.length>0 ? `&mobileNo=${data.field.contactNo}` : '';
  let k= x.monthWise.length>0 ? `&monthYear=${data.field.monthWise}` : '';
  let f = a + b + c + d + e + k;
  return http_common.get(`/vehicle/search/entryexit?pageNo=${data.pageNo}&pageSize=${data.pageSize}${f}`);
}

const downloadEntryExitExcel = (data) => {
  let x = data && data.field && data.field;
  let b = x && x.name && x.name.length>0 ? `&name=${data.field.name}` : '';
  let c = x && x.block && x.block.length>0 ? `&blockNo=${data.field.block}` : '';
  let d= x && x.vehicleNo && x.vehicleNo.length>0 ? `&vehicleNos=${data.field.vehicleNo}` : '';
  let e= x && x.contactNo && x.contactNo.length>0 ? `&mobileNo=${data.field.contactNo}` : '';
  let k= x && x.monthWise && x.monthWise.length>0 ? `&monthYear=${data.field.monthWise}` : '';
  let f =  b + c + d + e + k;
  let url;
  if(data){
      url = `/vehicle/search/entryexit/csv?${f}`
  }
  else{
      url = `/vehicle/search/entryexit/csv` 
  }
  return http_common.get(url);
}

export default {
    getUserList, updateUser, registerAccessCard, getAccessList,
    deleteAccessCard, addAccessCard, editAccessCard,
    getAccessLogs,updateUserDetails,getVehicleAuditReport,
    searchEntryExit,downloadEntryExitExcel,getGateLogs
}
