import axios from "axios";
import UrlConfigs from "../configs/UrlConfig";


export default axios.create({
  baseURL: UrlConfigs.baseUrl,
  //baseURL: 'https://e3fb-2405-201-e013-c916-6875-a915-2702-5ab1.in.ngrok.io/api/v1/',
  headers: {
    'Content-type': 'application/json',
    'Authorization': 'Basic bWlnaHR5aWFkbWluOmNvbmRvQDEyMw=='
  },
});
