import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import colorConfigs from '../configs/colorConfigs';
import { Card, CardContent } from '@mui/material';
import Logo from '../Assets/Logo.png';
import { useNavigate } from "react-router-dom";
import { SignUpValidateForm } from './FormValidation';
import AuthService from '../Services/AuthService';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import BackDropLoading from "../Components/Loading";

let validation = {
  name: false,
  email: false,
  mobile: false,
  password: false,
  confirmPassword: false
}

let validationMsg = {
  name: '',
  email: '',
  mobile: '',
  password: '',
  confirmPassword: ''
}

export default function SignUp() {
    const navigate = useNavigate();
    const[name, setName] = useState('')
    const[email, setEmail] = useState('')
    const[mobile, setMobile] = useState('')
    const[password, setPassword] = useState('')
    const[confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [valid, setValid] = useState(validation)
    const [msg, setMsg] = useState(validationMsg)

    useEffect(()=>{
      setValid(validation)
      setMsg(validationMsg)
    },[name, email, mobile, password, confirmPassword])

    

    const handleRegistration = async() => {
      setIsLoading(true)
        let data = {
          name: name,
          email: email,
          mobile: mobile,
          password: password,
          confirmPassword: confirmPassword
        }
        let err = SignUpValidateForm(data)
        console.log("err", err)
        if(err == null){
          await AuthService.onSignUp(data).then((response)=>{
            console.log("response", response)//user.uid
            let uId = response && response.user && response.user.uid;
            let value = {
              "emailId": email,
              "firstName": name,
              "lastName": "",
              "mobileNumber": mobile,
              "password": password,
              "uid": uId
            }
            onRegister(value)
          }).catch((err)=>{
            let errData = err && err.code && err.code.split("/");
            let eValue = errData[1].replace(/-/g, " ")
            let errorValue = eValue ? eValue :"Service unavailable, Kindly try after sometime"
            setIsLoading(false)
            toast.error(errorValue);
          })
         
        }
        else{
          setIsLoading(false)
            if(err && err.email){
                setMsg((prevState) => ({
                    ...prevState,
                    email: err.email
                }));
                setValid((prevState) => ({
                    ...prevState,
                    email: true
                }));
            }
            if(err && err.name){
              setMsg((prevState) => ({
                  ...prevState,
                  name: err.name
              }));
              setValid((prevState) => ({
                  ...prevState,
                  name: true
              }));
            }
            if(err && err.mobile){
              setMsg((prevState) => ({
                  ...prevState,
                  mobile: err.mobile
              }));
              setValid((prevState) => ({
                  ...prevState,
                  mobile: true
              }));
          }
            if(err && err.password){
                setMsg((prevState) => ({
                    ...prevState,
                    password: err.password
                }));
                setValid((prevState) => ({
                    ...prevState,
                    password: true
                }));
            }
            if(err && err.confirmPassword){
              setMsg((prevState) => ({
                  ...prevState,
                  confirmPassword: err.confirmPassword
              }));
              setValid((prevState) => ({
                  ...prevState,
                  confirmPassword: true
              }));
          }
        }
    }

    const onRegister=async(data)=>{
      await AuthService.registerUser(data, 'admin').then((response)=>{
        console.log("response", response)
        toast.success("Registered Successfully");
        setIsLoading(false)       
        navigate("/login")
      }).catch((e)=>{
        let err = e && e.response && e.response.data && e.response.data.error &&
        e.response.data.error.reason;
        let errValue = err ? err :"Service unavailable, Kindly try after sometime"
        setIsLoading(false)
        toast.error(errValue);
      })
      
    }

      


  return (
    //<div >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{backgroundColor: colorConfigs.mainBg, minHeight: '100vh'}}
        >
            <Card sx={{ maxWidth: 500 }}>
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={Logo} width={`60%`} height={40}/>
                </Grid>
                
                  <Grid container spacing={2}  style={{padding: `10px`}}> 
                      <Grid item xs={12} md={12} sm={12}>
                          <TextField
                              required
                              id="email"
                              label="Email"
                             
                              error ={valid.email}
                              onChange={(e)=> setEmail(e.target.value)}
                              helperText={valid.email ? msg.email : ''}
                              fullWidth
                          />
                      </Grid>  

                      <Grid item xs={12} md={12} sm={12}>
                          <TextField
                              required
                              id="name"
                             
                              label="Name"
                              error ={valid.name}
                              onChange={(e)=> setName(e.target.value)}
                              helperText={valid.name ? msg.name : ''}
                              fullWidth
                          />
                      </Grid>  

                      <Grid item xs={12} md={12} sm={12}>
                          <TextField
                              required
                              id="mobile"
                              type='number'
                              label="Mobile"
                              inputProps={{maxLength :1}}
                              error ={valid.mobile}
                              onChange={(e)=> setMobile(e.target.value)}
                              helperText={valid.mobile ? msg.mobile : ''}
                              fullWidth
                          />
                      </Grid>  

                      <Grid item xs={12} md={12} sm={12}>
                          <TextField
                              required
                              id="password"
                              label="Password"
                             
                              type='password'
                              error ={valid.password}
                              onChange={(e)=> setPassword(e.target.value)}
                              helperText={valid.password ? msg.password : ''}
                              fullWidth
                          />
                      </Grid>   

                      <Grid item xs={12} md={12} sm={12}>
                          <TextField
                              required
                              id="oupasswordrep"
                              label="Confirm Password"
                              type='password'
                             
                              error ={valid.confirmPassword}
                              onChange={(e)=> setConfirmPassword(e.target.value)}
                              helperText={valid.confirmPassword ? msg.confirmPassword : ''}
                              fullWidth
                          />
                      </Grid>                       
                  </Grid>                    
               
                <Grid item xs={12} md={12} sm={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      onClick={()=> handleRegistration()}>
                      Sign Up
                    </Button>
                </Grid>
                <Grid container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center" style={{padding: `10px`}}
                  spacing={1}>
                  <Grid item xs={4} sm={6} md={6}>
                    <Link onClick={()=>  navigate("/recoverpassword")}  variant="body2">
                      Recover Password
                    </Link>
                  </Grid>
                  <Grid item xs={8} sm={6} md={6} >
                    <Link onClick={()=>  navigate("/login")}  variant="body2" style={{float: 'right'}}>
                      {"Already have an account? Sign In"}
                    </Link>
                  </Grid>
                </Grid>
              </CardContent>
              {isLoading && <BackDropLoading  open={isLoading} />}
              <ToastContainer />
            </Card>
            
        </Grid>
    //</div>
  );
}