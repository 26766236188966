import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import StreamIcon from '@mui/icons-material/Stream';
import MapIcon from '@mui/icons-material/Map';
import AddCardIcon from '@mui/icons-material/AddCard';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import Dashboard from '../Pages/Dashboard';
import AccessCardRegistration from '../Pages/AccessCardRegistration';
import Map from '../Pages/Maps/index';
import LiveFeedsDashboard from '../Pages/LiveFeed';
import VisitorRegistration from '../Pages/VisitorRegistration/VisitorRegistration';
import DatabaseStreaming from '../Pages/Database/Database';
import AccessCardLog from '../Pages/AccessCardLog';
import VehicleReport from '../Pages/VehicleReport';
import EntryExit from '../Pages/EntryExit';
import GateLog from '../Pages/GateLogs';

const appRoutes = [
    /* {
        index: true,
        element: <Map />,
        state: "Map"
    }, */
    {
      path: "/dashboard",
      element: <Dashboard />,
      state: "UserApproval",
      sidebarProps: {
        displayText: "User Approval",
        icon: <DashboardOutlinedIcon />
      }
    },

    /* {
      path: "/smart-patrol",
      element: <LiveFeedsDashboard />,
      state: "SmartPatrol",
      sidebarProps: {
        displayText: "Smart Patrol",
        icon: <StreamIcon />
      }
    },

    {
      path: "/database",
      element: <DatabaseStreaming />,
      state: "Database",
      sidebarProps: {
        displayText: "Database",
        icon: <StorageOutlinedIcon />
      }
    }, 
    
    {
        path: "/user-approval", //"/user-approval",
        element: <Dashboard />,
        state: "UserApproval",
        sidebarProps: {
          displayText: "User Approval",
          icon: <DashboardOutlinedIcon />
        }
    },*/

    {
      path: "/access-card-registration",
      element: <AccessCardRegistration />,
      state: "AccessCardRegistration",
      sidebarProps: {
        displayText: "Access Card Registration",
        icon: <AddCardIcon />
      }
    },

    {
      path: "/access-card-logs",
      element: <AccessCardLog />,
      state: "AccessCardLog",
      sidebarProps: {
        displayText: "Access Card Logs",
        icon: <AddCardIcon />
      }
    },
    {
      path: "/vehicle-report",
      element: <EntryExit />,
      state: "VehicleReport",
      sidebarProps: {
        displayText: "Vehicle Audit Report",
        icon: <AddCardIcon />
      }
    },
    /* {
      path: "/visitor-registration",
      element: <VisitorRegistration />,
      state: "VisitorRegistration",
      sidebarProps: {
        displayText: "Visitor Registration",
        icon: <PersonAddIcon />
      }
    }, */

    {
      path: "/gate-logs",
      element: <GateLog/>,
      state: "GateLog",
      sidebarProps: {
        displayText: "Gate Logs",
        icon: <AddCardIcon />
      }
    },


    
];

export default appRoutes;