import React,{useState, useEffect} from "react";
import { Card, TextField, MenuItem} from "@mui/material";
import DataSerices from "../Services/DataSerices";
import MUIDataTable from "mui-datatables";

import BackDropLoading from "../Components/Loading";

const statusList =  [
    {
        type: 'QR',
        value: 'QR'
    },
    {
        type: 'Access Card',
        value: 'AccessCard'
    }
]

function CustomToolbarComponent({handleChange, type}) {
    const handleSelect=(e)=>{
        handleChange(e.target.value)
    }

    return (
        <>
            <TextField
            id="outlined-select-currency"
            select
            size="small"
            label="Select Alert Type"
            value={type}
            //style={{width: 180, padding: 2}}
            onChange={(e)=> handleSelect(e)}
            >
            {statusList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                {option.type}
                </MenuItem>
            ))}
            </TextField>
        </>
    );
};



function AccessCardLog(){
    const [list, setList] = useState([]);
    const [duplicateList, setDuplicateList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState('AccessCard');
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const handleChange=(e)=>{
        setType(e)
    }

    const options = {
        filter: false,
        selectableRows: false,
        search: true,
        serverSide: false,
        download: true,
        print: false,
        viewColumns: false,
        pagination: true,
        count: count,
        page: page,
        rowsPerPage: pageSize,
        rowsPerPageOptions: [10, 25, 50],
        onTableChange: (action, tableState) => {
            console.log(action)
            switch (action) {
                case "changePage":
                    console.log("changePage", tableState)
                    if (list.length > 9) {
                        setPage(tableState.page)
                    }
                    break;
                case "changeRowsPerPage":
                    setPageSize(tableState.rowsPerPage)
                    break;
            }
        },
        customToolbar: () => {
            return (
                <CustomToolbarComponent  
                type={type}
                handleChange={handleChange}/>
            );
        }
    };

    const columns =[
        {
            name: "id",
            label: "S/No.",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span>{tableMeta.rowIndex + 1 + page * pageSize}</span>
                    );
                }
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "cardNumber",
            label: "Card Number",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "recordTime",
            label: "Record Time",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "deviceSerialNum",
            label: "Serial Number",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "gateNo",
            label: "Gate Number",
            options: {
                filter: false,
                sort: false,
            }
        },
    ]

    useEffect(()=>{
        getList();
    },[page, pageSize, type])

    const getList=async()=>{
        setIsLoading(true)
        let data = {
            page: page,
            size: pageSize,
            type: type,
        }
        await DataSerices.getAccessLogs(data).then((res)=>{
            let response = res?.data?.data?.content;
            setList(response)
            setDuplicateList(response)
            setCount(res?.data?.data?.totalElements)
            setIsLoading(false)
        }).catch((error) => {
            console.log("error", error)
            setIsLoading(false)
        });
    }

    return(
        <React.Fragment>
            <Card>
                {isLoading && <BackDropLoading  open={isLoading} />}           
                <MUIDataTable
                title={"Access Card Logs"}
                data={list}
                columns={columns}
                options={options}
                />
               
            </Card>
        </React.Fragment>
    );

}

export default AccessCardLog