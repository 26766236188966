import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Grid, Typography } from '@mui/material';
import { useTheme } from "@mui/material";
import StreamRoundedIcon from '@mui/icons-material/StreamRounded';
import AddAlertRoundedIcon from '@mui/icons-material/AddAlertRounded';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ReportsInfo from '../../Constants/zone';
import LiveFeedStreaming from './LiveFeedStreaming';
import AlertStreaming from './Alert/AlertStreaming';
import PatrolStreaming from './Patrol/Patrol';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const LiveFeedsDashboard=()=>{
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [condo, setCondo] = React.useState(ReportsInfo.Condo[0].value);

    const handleTabChange=(e, n)=>{
      console.log(e, n)
      setValue(n)
    }


   
    return (
       <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} style={{marginTop: `-20px`}}>
            <Grid container spacing={2} >
              <Grid item xs={12} sm={10} md={10}  style={{padding: `10px`}}>
                <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="icon position tabs example"
                variant="scrollable"
                scrollButtons="auto"
                variant="fullWidth"
                >
                  <Tab icon={<StreamRoundedIcon />} iconPosition="start" label="Live Feeds"  {...a11yProps(0)}/>
                  <Tab icon={<AddAlertRoundedIcon />} iconPosition="start" label="Alerts"  {...a11yProps(1)}/>
                  <Tab icon={<SecurityOutlinedIcon />} iconPosition="start" label="Patrol"  {...a11yProps(1)}/>
                </Tabs>
              </Grid>
              <Grid item xs={12} sm={2} md={2} style={{padding: `30px`}}>
                  <TextField
                      id="outlined-select-currency"
                      select
                      label="Condo Name"
                      size='small'
                      fullWidth
                      value={condo}
                      onChange={(e)=> setCondo(e.target.value)}
                      
                  >
                  {ReportsInfo.Condo.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                      {option.label}
                      </MenuItem>
                  ))}
                  </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
              {value == 0 &&(
                <LiveFeedStreaming />
              )}
               {value == 1 &&(
                <AlertStreaming />
                )}
                {value == 2 &&(
                <PatrolStreaming />
              )}              
          </Grid>     
       </Grid>
      );
}


export default LiveFeedsDashboard;

/*
 <Tabs
    value={value}
    //onChange={handleChange}
    aria-label="icon position tabs example"
  >
    <Tab icon={<PhoneIcon />} label="top" />
    <Tab icon={<PhoneMissedIcon />} iconPosition="start" label="start" />
    <Tab icon={<FavoriteIcon />} iconPosition="end" label="end" />
    <Tab icon={<PersonPinIcon />} iconPosition="bottom" label="bottom" />
  </Tabs>
*/