import React,{useState, useEffect} from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, 
    Button, Typography, Grid, TextField } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import DataSerices from '../Services/DataSerices';
import BackDropLoading from "../Components/Loading";

const successMsg = "Access Card Saved Successfully"
const updateMsg = "Access Card Updated Successfully"

const RegisterPopUp = ({ open, handleClose, data}) => {
    const [name, setName] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [unitNo, setUnitNo] = useState('');
    const [cardNo, setCardNo] = useState('');
    const [errors, setErrors]= useState({
        name: false,
        mobileNo: false,
        unitNo: false,
        cardNo: false
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        if(data && data.status == 'Edit'){
            let d = data && data.data;
            setName(d.name);
            setMobileNo(d.mobileNo);
            setUnitNo(d.unitNo);
            setCardNo(d.cardNo);
        }
    },[data])

  
    const handleTextField =() =>{
        return(
            <Grid container spacing={0}>

                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        margin="normal"
                        required
                        error={errors.cardNo}
                        helperText={errors.cardNo ? "Please enter your card number" : ''}
                        value={cardNo}
                        onChange={(event) => setCardNo(event.target.value)}
                        fullWidth
                        id="cardNo"
                        label="Card Number"
                        disabled={data.status == 'Create' ? false : true}
                        name="cardNo"
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        margin="normal"
                        required
                        error={errors.name}
                        helperText={errors.name ? "Please enter your name" : ''}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        margin="normal"
                        required
                        error={errors.mobileNo}
                        helperText={errors.mobileNo ? "Please enter valid mobile number" : ''}
                        type='number'
                        value={mobileNo}
                        onChange={(event) => {
                            if(event.target.value.length < 9){
                                setMobileNo(event.target.value)
                            }
                        }}
                        fullWidth
                        id="mobileNo"
                        label="Mobile Number"
                        name="mobileNo"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        margin="normal"
                        required
                        error={errors.unitNo}
                        helperText={errors.unitNo ? "Please enter your unit number" : ''}
                        value={unitNo}
                        onChange={(event) => setUnitNo(event.target.value)}
                        fullWidth
                        id="unitNo"
                        label="Unit Number"
                        name="unitNo"
                    />
                </Grid>
              
            </Grid>  
        );
    }

    const handleModalClose=(e)=>{
        setName('');
        setMobileNo('');
        setUnitNo('');
        setCardNo('');
        handleClose(e)
    }

    const handleSubmit=async()=>{
        let checkName = name.length>0;
        let checkMobileNo = mobileNo.length == 8;
        let checkUnitNo = unitNo.length>0;
        let checkCardNo = cardNo.length>0;
        let errorData = {
            name: !checkName,
            mobileNo: !checkMobileNo,
            unitNo: !checkUnitNo,
            cardNo: !checkCardNo
        }
        setErrors(errorData)
        if(checkName && checkMobileNo && checkUnitNo && checkCardNo){
            let value = {
                name: name,
                mobileNo: mobileNo,
                unitNo: unitNo,
                cardNo: cardNo,
                status: "ACTIVE"
            }
            setIsLoading(true)
            console.log("handleSubmit", value)
            if(data.status == 'Create'){
                await DataSerices.registerAccessCard(value).then((res)=>{
                    console.log(res)
                    handleModalClose(successMsg)
                    setIsLoading(false)
                }).catch((error) => {
                    console.log("error", error)
                });
            }
            if(data.status == 'Edit'){
                await DataSerices.editAccessCard(value).then((res)=>{
                    console.log(res)
                    handleModalClose(successMsg)
                    setIsLoading(false)
                }).catch((error) => {
                    console.log("error", error)
                });
            }            
        }
        
    }


  return (
    <Dialog open={open} onClose={()=>handleModalClose(null)}>
      {isLoading && <BackDropLoading  open={isLoading} />}           
      <DialogTitle>{data.status == 'Create' ? `Register` : data.status}</DialogTitle>
      <DialogContent>
        {handleTextField()}
      </DialogContent>
      <DialogActions>
        <Button  variant="contained" onClick={()=> handleSubmit()}>
          Save
        </Button>
        <Button onClick={()=>handleModalClose(null)} variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegisterPopUp;