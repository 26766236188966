import React, { useState, useEffect } from 'react';
import Map, { Layer, MapRef, Marker, Source, Popup, FullscreenControl, NavigationControl } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css'
import UrlConfigs from '../../configs/UrlConfig';
import Pin from '../../Assets/pin.png'
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


const MapComponent=()=>{
    const [viewport, setViewport] = useState({
        longitude: 103.90294597766965,
        latitude: 1.358530165383519,
        zoom: 12
    });
    const [reRenderMap, setReRenderMap] = useState(1)

    /* useEffect(()=>{
      console.log("vhshsh")
    },[sessionStorage.getItem("sidebarPosition")]) */
   
    const getPinLastLocation =()=>{
        return(
            <Marker
            key={'marker'}
            latitude={viewport.latitude}
            longitude={viewport.longitude}
            >
              <div>
                <img src={Pin} className="mrt-marker"  height={40}/>
              </div>        
            </Marker>
          )
      }

    return(
        <Map
        initialViewState={viewport}
        mapboxAccessToken={UrlConfigs.mapBox}
        style={{width: `100%`, height: `80vh`}}
        mapStyle={UrlConfigs.mapStyle}
        onRender={(event) => event.target.resize()}
        >
            {getPinLastLocation()}
        </Map>
    )
}   

export default MapComponent